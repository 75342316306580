import React from "react";
import { Heading, GeneralTextWithImgWrapper } from "./style";
import { RichText } from "./../../styles/richText";
import PropTypes from "prop-types";
import { CloudinaryImage } from "@website-builder/ui/shared/elements";

const GeneralTextWithImg = ({
	header,
	contentInHtml,
	headerSize,
	imageConfig: { imageUrl, altTag },
	mobileImageConfig,
	imagePlacement,
}) => {
	const mobileImageUrl = mobileImageConfig?.imageUrl || "";
	const mobileAltTag = mobileImageConfig?.altTag || "";
	return (
		<GeneralTextWithImgWrapper placement={imagePlacement}>
			<div className="general-text-content">
				<Heading headerSize={headerSize}>{header}</Heading>
				<RichText>
					<div
						className="rich-text-wrapper"
						dangerouslySetInnerHTML={{ __html: contentInHtml }}
					></div>
				</RichText>
			</div>
			<CloudinaryImage
				className="general-text-image"
				url={imageUrl}
				sizes={{
					default: {
						width: 240,
					},
				}}
				fetchFormat="auto"
				quality="auto"
				alt={altTag}
				mobileImageUrl={mobileImageUrl}
				mobileAltTag={mobileAltTag}
			/>
		</GeneralTextWithImgWrapper>
	);
};

GeneralTextWithImg.propTypes = {
	header: PropTypes.string.isRequired,
	contentInHtml: PropTypes.string.isRequired,
	headerSize: PropTypes.oneOf(["header26", "header22"]),
	imageConfig: PropTypes.shape({
		imageUrl: PropTypes.string.isRequired,
		alttag: PropTypes.string,
	}),
	imagePlacement: PropTypes.oneOf(["right", "left"]),
};

export default GeneralTextWithImg;
