import styled from "styled-components";
import {
	FONT_SIZE_18,
	FONT_SIZE_22,
	FONT_SIZE_26,
	MOBILE_L_BP,
	TABLET_M_BP,
	FONT_WT_LIGHT,
} from "@website-builder/utilities-index";

export const Heading = styled.h2`
	font-size: ${({ headerSize }) =>
		headerSize?.value && headerSize.value === "header26" //For Backward Compatibility
			? FONT_SIZE_26
			: !headerSize.value
				? headerSize + "px"
				: FONT_SIZE_22};
	margin: 0;
	margin-bottom: 25px;

	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		font-size: ${FONT_SIZE_18};
		line-height: 23px;
	}
`;

export const GeneralTextWithImgWrapper = styled.section`
  display: flex;

  @media only screen and (max-width: ${TABLET_M_BP}px) {
    display: block;
  }

  .general-text-content {
    margin-${({ placement }) => placement}: 40px;
    order: ${({ placement }) => (placement == "right" ? 1 : 2)};

    @media only screen and (max-width: ${TABLET_M_BP}px) {
        margin:0px;
    }
  }

  picture {
    display: flex;
    align-items: center;
    order: ${({ placement }) => (placement == "right" ? 2 : 1)};

    @media only screen and (max-width: ${TABLET_M_BP}px) {
        display: block;
        margin:30px 10px;
        text-align:center;
    }
  }

  .general-text-image {
    width: 240px;
  }

  a,
  a:visited,
  a:active,
  a:hover {
    font-weight: ${FONT_WT_LIGHT};
    color: inherit;
  }
`;
